import { ReactNode } from 'react'

type TIf = {
  children?: ReactNode
  condition?: boolean | null | number
  renderIf?: JSX.Element | false
  renderElse?: JSX.Element | false
}

const If = ({
  children,
  condition,
  renderIf = false,
  renderElse = false,
}: TIf) => {
  const ifElement = children ? children : renderIf
  return condition ? ifElement : renderElse
}

export default If
