import { useEffect, useState } from 'react'

import browser from '@utils/browser'

export const useScreenDetector = () => {
  const [width, setWidth] = useState(browser.isClient() && window?.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(browser.isClient() && window?.innerWidth)
  }

  useEffect(() => {
    if (browser.isClient()) {
      window.addEventListener('resize', handleWindowSizeChange)

      return () => {
        window.removeEventListener('resize', handleWindowSizeChange)
      }
    }
  }, [])

  const isMobile = width && width <= 768
  const isTablet = width && width <= 1024
  const isDesktop = width && width > 1024

  return { isMobile, isTablet, isDesktop }
}

export default useScreenDetector
