import axios from 'axios'

import { API_ROOT } from 'gatsby-env-variables'

const instance = axios.create({
  baseURL: API_ROOT,
  timeout: 10000,
})

export default instance
