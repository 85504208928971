const isClient = () =>
  !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  )

const scrollToTop = () => {
  if (isClient()) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

const loader = {
  show: () =>
    isClient() &&
    document.querySelector('.loader')?.classList?.add('is-active'),
  hide: () =>
    isClient() &&
    setTimeout(
      () => document.querySelector('.loader')?.classList?.remove('is-active'),
      500,
    ),
}

export default { isClient, scrollToTop, loader }
