import type { GatsbyBrowser } from 'gatsby'

import * as React from 'react'

// import './src/shared/styles/index.scss'
import Layout from './src/shared/layout'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  return <Layout {...props}>{element}</Layout>
}
