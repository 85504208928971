import classNames from 'classnames'

import React from 'react'

import If from '@components/If'

import IconArrow from '@icons/arrow-complete.svg'

import './Billboard.scss'
import useBillBoard from './useBillboard'

const Billboard = () => {
  const { tickers } = useBillBoard()

  return (
    <section className="billboard">
      <ul className="billboard__tickers">
        {tickers.map((ticker) => {
          const isNegativeMarketChange =
            ticker?.regularMarketChangePercent &&
            ticker?.regularMarketChangePercent < 0

          return (
            <li className="billboard__ticker">
              <span className="billboard__ticker-name">{ticker.shortName}</span>
              <span className="billboard__ticker-value">
                {ticker.regularMarketPrice}
              </span>
              <div
                className={classNames('billboard__ticker-percert', {
                  'billboard__ticker-percert--negative': isNegativeMarketChange,
                })}
              >
                <If
                  condition={isNegativeMarketChange}
                  renderIf={<IconArrow className="billboard__icon-negative" />}
                  renderElse={
                    <IconArrow className="billboard__icon-positive" />
                  }
                />
                <span className="billboard__ticker-change">
                  {ticker.regularMarketChangePercent}
                </span>
              </div>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default Billboard
