import React from 'react'

import Logo from '@images/logo-icon.png'

import './Loader.scss'

const Loader = () => {
  return (
    <div className="loader" id="loader">
      <div className="loader__spinner" />
      <img src={Logo} alt="Carregando..." className="loader__logo" />
    </div>
  )
}

export default Loader
