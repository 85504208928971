module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gestivas: Sua enciclopédia financeira!","short_name":"Gestivas","start_url":"/","background_color":"#ffffff","theme_color":"#03256c","display":"minimal-ui","icon":"C:\\Users\\brrod\\devs\\gestivas-www/src/shared/images/logo-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fc1e2a86036372c1932b8ac34089c0de"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
