import { useEffect, useRef, useState } from 'react'

const useHeader = () => {
  const [isActiveMenu, setIsActiveMenu] = useState(false)
  const headerRef = useRef<HTMLInputElement | null>(null)

  const onToggleMenu = () => {
    setIsActiveMenu(!isActiveMenu)
  }

  return {
    isActiveMenu,
    onToggleMenu,
  }
}

export default useHeader
