import { Link } from 'gatsby'

import React from 'react'

import Billboard from './components/Billboard'

// import If from '@components/If'
import IconLogo from '@icons/logo.svg'

// import IconMenu from '@icons/menu.svg'
// import IconSearch from '@icons/search.svg'
import useScreenDetector from '@hooks/useScreenDetector'

import './Header.scss'
import useHeader from './useHeader'

const Header = () => {
  const { onToggleMenu, isActiveMenu } = useHeader()
  const { isMobile, isDesktop } = useScreenDetector()

  return (
    <>
      <header className="header">
        <div className="header__container">
          <Link to="/">
            <IconLogo className="header__logo" />
          </Link>
        </div>
      </header>
      <Billboard />
    </>
  )
}

export default Header
