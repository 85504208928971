import instance from '@services/http'

import number from '@utils/number'

export type TResponseTickers = {
  regularMarketChange: number
  regularMarketChangePercent?: number
  regularMarketPrice: number
  shortName: string
  symbol: string
}

const toFormatter = (ticker: TResponseTickers) => ({
  ...ticker,
  regularMarketChangePercent: number.roundingWithDecimalPlace(
    ticker?.regularMarketChangePercent,
  ),
})

const getIndexes = () => {
  return instance
    .get<TResponseTickers[]>('/indexes')
    .then((res) => res?.data)
    .then((tickers) => tickers.map(toFormatter))
}

export default {
  getIndexes,
}
