const roundingWithDecimalPlace = (number?: number) => {
  if (number) {
    const rouding = Math.round(number * 100) / 100

    return rouding
  }
}

const toCurrencyBRL = (number?: number | string) => {
  const toNumber = Number(number)
  const formatted = number?.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  return formatted
}

const toRounding = (number?: number) => {
  if (number) {
    return Math.round(number)
  }
}

export default {
  roundingWithDecimalPlace,
  toCurrencyBRL,
  toRounding,
}
