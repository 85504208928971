import { useCallback, useEffect, useState } from 'react'

import services, { TResponseTickers } from './services'

const useBillBoard = () => {
  const [tickers, setTickers] = useState<TResponseTickers[]>([])

  const fetchIndexes = useCallback(
    () => services.getIndexes().then(setTickers),
    [],
  )

  useEffect(() => {
    fetchIndexes()
  }, [fetchIndexes])

  return { tickers }
}

export default useBillBoard
