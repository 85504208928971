import React from 'react'

import Footer from './components/Footer'
import Header from './components/Header'
import Loader from './components/Loader'

import './Layout.scss'

type TLayout = {
  children: any
}

const Layout = ({ children }: TLayout) => {
  return (
    <>
      <Header />
      <Loader />
      <main className="layout-app">{children}</main>
      <Footer />
    </>
  )
}

export default Layout
