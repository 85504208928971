import { Link } from 'gatsby'

import React from 'react'

import IconLogo from '@icons/logo.svg'

import './Footer.scss'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <Link to="/">
          <IconLogo className="header__logo" />
        </Link>
        <p className="footer__policy">
          Copyright © Gestivas - 2023. Todos os direitos reservados |
        </p>
      </div>
    </footer>
  )
}

export default Footer
